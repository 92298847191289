export const $tool = {
  isPC() {
    const flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    // const userAgentInfo = navigator.userAgent // navigator.userAgent返回的是Netscape
    // const Agents = [
    //   'Android',
    //   'iPhone',
    //   'SymbianOS',
    //   'windows Phone',
    //   'iPod',
    //   'iPad'
    // ]
    // let flag = true

    // for (let v = 0; v < Agents.length; v++) {
    //   if (userAgentInfo.indexOf(Agents[v]) > -1) {
    //     flag = false //为手机端，将flag改为false，并返回flag值
    //     break
    //   }
    // }

    return flag ? false : true
  }
}
export default $tool

export function isPhone(str: string) {
  const reg = /^1[3-9]\d{9}$/
  return reg.test(str)
}
// 手机号校验
export const validatePhone = () => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (rule: any, value: string, callback: Function) => {
    if (!value) {
      return callback(new Error(' '))
    } else {
      const reg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (!reg.test(value)) {
        return callback(new Error(' '))
      } else {
        return callback()
      }
    }
  }
}
