import state from './state'
import mutations from './mutations'
import getters from './getters'
// import actions from './actions'
export default {
  namespaced: true, // 防止模块命名冲突 设置后调用mutations和action需要
  //   '模块名/方法' 例如  app/setMenuList
  state: state,
  getters: getters,
  mutations: mutations
  // actions: actions
}
