import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import $tool from '@/utils/common'
const isPc = $tool.isPC()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: (): unknown => {
      return isPc
        ? import('@/views/pc/index.vue')
        : import('@/views/mobile/index.vue')
    },
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'index',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/home/index.vue')
            : import('@/views/mobile/home/index.vue')
        }
      },
      {
        path: '/message',
        name: 'message',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/view/message.vue')
            : import('@/views/mobile/view/message.vue')
        }
      },
      {
        path: '/cloud-desktop',
        name: 'cloudDesktop',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/product/cloud-desktop.vue')
            : import('@/views/mobile/product/cloud-desktop.vue')
        },

        meta: {
          hoverMenu: 'product'
        },
        redirect: '/cloud-desktop/all',
        children: [
          {
            path: '/cloud-desktop/all',
            name: 'cloudDesktopAll',
            component: (): unknown => {
              return isPc
                ? import('@/views/pc/product/view/all-desktop.vue')
                : import('@/views/mobile/product/view/all-desktop.vue')
            },
            meta: {
              hoverMenu: 'product'
            }
          },
          {
            path: '/cloud-desktop/edu',
            name: 'cloudDesktopEdu',
            component: (): unknown => {
              return isPc
                ? import('@/views/pc/product/view/edu-desktop.vue')
                : import('@/views/mobile/product/view/edu-desktop.vue')
            },

            meta: {
              hoverMenu: 'product'
            }
          }
        ]
      },
      {
        path: '/private-cloud',
        name: 'privateCloud',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/product/private-cloud.vue')
            : import('@/views/mobile/product/private-cloud.vue')
        },
        meta: {
          hoverMenu: 'product'
        }
      },
      {
        path: '/hyperfusion',
        name: 'hyperfusion',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/product/hyperfusion.vue')
            : import('@/views/mobile/product/hyperfusion.vue')
        },
        meta: {
          hoverMenu: 'product'
        }
      },
      {
        path: '/education',
        name: 'education',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/solution/education.vue')
            : import('@/views/mobile/solution/education.vue')
        },
        meta: {
          hoverMenu: 'solution'
        }
      },
      {
        path: '/government',
        name: 'government',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/solution/government.vue')
            : import('@/views/mobile/solution/government.vue')
        },
        meta: {
          hoverMenu: 'solution'
        }
      },
      {
        path: '/classroom',
        name: 'classroom',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/solution/classroom.vue')
            : import('@/views/mobile/solution/classroom.vue')
        },
        meta: {
          hoverMenu: 'solution'
        }
      },
      {
        path: '/resource',
        name: 'resource',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/solution/resource.vue')
            : import('@/views/mobile/solution/resource.vue')
        },
        meta: {
          hoverMenu: 'solution'
        }
      },
      {
        path: '/network',
        name: 'network',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/solution/network.vue')
            : import('@/views/mobile/solution/network.vue')
        },
        meta: {
          hoverMenu: 'solution'
        }
      },
      {
        path: '/about-us',
        name: 'aboutUs',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/about-us/index.vue')
            : import('@/views/mobile/about-us/index.vue')
        },
        meta: {
          hoverMenu: 'aboutUs'
        }
      },
      {
        path: '/company-news',
        name: 'companyNews',
        redirect: '/company-news/list',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/company-news/index.vue')
            : import('@/views/mobile/company-news/index.vue')
        },
        meta: {
          hoverMenu: 'companyNews'
        },
        children: [
          {
            path: '/company-news/list',
            name: 'NewsList',
            component: (): unknown => {
              return isPc
                ? import('@/views/pc/company-news/views/news-list.vue')
                : import('@/views/mobile/company-news/views/news-list.vue')
            },
            meta: {
              hoverMenu: 'companyNews'
            }
          },
          {
            path: '/company-news/detail',
            name: 'NewsDetail',
            component: (): unknown => {
              return isPc
                ? import('@/views/pc/company-news/views/news-detail.vue')
                : import('@/views/mobile/company-news/views/news-detail.vue')
            },
            meta: {
              hoverMenu: 'companyNews'
            }
          }
        ]
      },
      {
        path: '/business-cooperation',
        name: 'businessCooperation',
        component: (): unknown => {
          return isPc
            ? import('@/views/pc/business-cooperation/index.vue')
            : import('@/views/mobile/business-cooperation/index.vue')
        },
        meta: {
          hoverMenu: 'businessCooperation'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

//获取所有路由扁平化后的路径
const flatRoute: string[] = []
const fn = function (ary: any) {
  for (let i = 0; i < ary.length; i++) {
    const item = ary[i]
    if (ary[i].children && Array.isArray(ary[i].children)) {
      fn(item.children)
    } else {
      flatRoute.push(item.path)
    }
  }
}
fn(routes)

// 路由全局守卫
router.beforeEach((to, from, next) => {
  if (flatRoute.includes(to.path)) {
    next()
    document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTop = 0
  } else {
    next('/')
  }
})

export default router
