import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible/index.js'
import 'animate.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import $tool from '@/utils/common'
const isPc = $tool.isPC()
if (isPc) {
  require('@/assets/css/common-pc.scss')
} else {
  require('@/assets/css/common-mobile.scss')
}
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(store).use(router).mount('#app')
