import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import global from './modules/global'

const requireComponents = require.context('./modules', true, /index\.ts/)
const modules: {
  [test: string]: any
} = {}

requireComponents.keys().forEach((file: any) => {
  const mod = requireComponents(file)
  if (file.includes('global') === false) {
    modules[file.split('/')[1]] = mod.default
  }
})

const store = createStore({
  state: global.state,
  getters: global.getters,
  mutations: global.mutations,
  // actions: global.actions,
  modules: modules,
  plugins: [createPersistedState({ storage: window.sessionStorage })]
})

export default store
