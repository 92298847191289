export enum Mutations {
  HEADEROPACITY = 'HEADEROPACITY'
}
const mutations = {
  HEADEROPACITY(state: { headerOpacity: string }, payload: string) {
    state.headerOpacity = payload
  }
}

export default mutations
